@import '../.resources/styles/footer-layout.css';

:root {
    --app-color-brand: #533f86;
    --app-color-brand-highlight: #9284be;
    --app-color-brand-dark: #533f86;
    --app-color-brand-light: #7463ac;
  
    --app-color-brand-accent: #3ec1cf;
    --app-color-brand-accent-highlight: #e7f6f8;
    --app-color-brand-accent-dark: #098398;
    --app-color-brand-accent-light: #7ed9e3;
  
    --app-color-off-white: #fff;
    --app-color-off-black: #000000;
  
    --app-background: rgba(62, 193, 207, 0.15);
    
    --app-header-height: 5em;
    --app-header-background: linear-gradient(
      222deg,
      var(--app-color-brand-accent-dark),
      var(--app-color-brand-accent) 50%,
      var(--app-color-brand-accent-dark)
    );
  
    --app-color-neutral-h: 165;
    --app-color-neutral-white: hsl(var(--app-color-neutral-h), 0%, 100%);
    --app-color-neutral: hsl(calc(var(--app-color-neutral-h) + 15), 2%, 58%);
  
    --app-color-shadow-line: hsla(189, 9%, 15%, 0.15);
    --app-shadow-low: 0 1px 4px var(--app-color-shadow-line);
    --app-shadow-mid: 0 2px 4px var(--app-color-shadow-line);
    --app-shadow-high: 0 5px 10px var(--app-color-shadow-line);
    --app-shadow-inset-low: inset 0 1px 4px var(--app-color-shadow-line);
    --app-shadow-inset-mid: inset 0 2px 4px var(--app-color-shadow-line);
    --app-shadow-inset-high: inset 0 5px 10px var(--app-color-shadow-line);

    --app-footer-background: var(--app-color-brand-accent-dark);
    --app-footer-text: var(--app-color-off-white);
  }
  
  @font-face {
    font-family: 'Korolev';
    src: url("../.resources/fonts/korolev/korolev-medium.woff2") format("woff2"),
      url("../.resources/fonts/korolev/korolev-medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    ascent-override: 110%;
  }
  
  body {
    background: var(--app-background);
  }
  
  body,
  input,
  textarea,
  select,
  button {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    line-height: 1.5;
  }
  
  h1,
  h2,
  h3 {
    font-family: Korolev, Arial, Helvetica, sans-serif;
    letter-spacing: -0.03em;
    line-height: 1.1;
  }
  
  section .app-contact-details__content a {
    color: var(--app-color-brand) !important;
  }
  
  section a {
    color: var(--app-color-brand) !important;
  }
  
  .app-locale-selector {
    color: var(--app-color-off-white) !important;
  }

  .app-locale-selector a {
    padding: 0 0 0 1em !important;
    height: 38px;
    display: flex !important;
    align-items: center;
    color: var(--app-color-off-black) !important;
  }

  .app-locale-selector a.router-link-exact-active {
    color: var(--app-color-brand-accent-dark) !important;
    background-color: var(--app-color-brand-accent-highlight);
  }

  .app-locale-selector a:not(:last-child) {
    border-bottom: 1px solid var(--app-color-neutral);
  }

  .app-locale-selector button {
    color: currentColor;
  }
  
  .app-locale-selector button svg {
    fill: var(--app-color-off-white);
  }

  .app-locale-selector nav {
    background: var(--app-color-off-white) !important;
  }
  
  .app-logo::after {
    content: url("../.resources/svgs/logo-pinatacasino.svg");
    width: 5.5em;
    margin-top: 0.4em;
  }
  
  .disclaimer-details .disclaimer-details__content {
    color: var(--app-color-off-black) !important;
  }
  